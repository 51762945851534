import { Button, Stack } from '@promptlylabs/skeleton';
import { APP_URL } from 'constants/urls';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getLogoUrl, getStaticUrl } from 'utils/static';
import '../404/style.scss';

const EventIdSpan = styled.span`
  margin-top: 10px;
  color: #88949d;
  font-size: 16px;
`;

const Title = styled.h1`
  margin-bottom: -30px;
`;
const Prom500Component = props => (
  <div className="container-http">
    <div className="logo">
      <img alt="Promptly" src={getLogoUrl('assets/images/logo.svg')} />
    </div>
    <div className="imgError">
      <img alt="500 error" src={getStaticUrl('assets/images/img-500.svg')} />
    </div>
    <Title>{props.t('Sorry, something went wrong. We won’t be long.')}</Title>
    <h2>{props.t('Please help us understand when this occured by reporting this error with the button bellow')}</h2>
    <Stack spacing={2} direction="row">
      <Link to={APP_URL}>
        <Button variant="contained" color="primary">
          {props.t('Go back home')}
        </Button>
      </Link>
      <Button color="gray.1" onClick={() => props.onClick(props.eventId)} variant="contained">
        {props.t('Report this error')}
      </Button>
    </Stack>
    <EventIdSpan>{props.eventId}</EventIdSpan>
  </div>
);

Prom500Component.propTypes = {
  t: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withNamespaces()(Prom500Component);
