import axios from '../../src/utils/axios';

// because we use ADD_TOAST payload as constant we need to translate message if user changes language
export const translations = store => next => action => {
  if (action.type === 'ADD_TOAST') {
    action.payload = {
      ...action.payload,
      message: window.i18next.t(action.payload.message),
    };
  }
  next(action);
};

/**
 * When patient selects an health space we set axios base url to hs domain url
 */
export const healthSpaceDomainURL = store => next => action => {
  if (action.type === 'SET_HEALTH_SPACE') {
    const { url } = action.payload;
    axios.setBaseURL(url);
  }
  next(action);
};
