export const PATIENT_CREATED_EVENT = 'patient_created';
export const LOCATION_CHANGED_EVENT = 'location_changed';
export const ACCEPT_CLINICAL_TRIAL_EVENT = 'patient_accepted_consent';
export const ACCEPT_AGREEMENT_EVENT = 'patient_accepted_tc';
export const SET_PASSWORD_EVENT = 'patient_defined_password';
export const CLICK_APP_DOWNLOAD_BANNER_EVENT = 'patient_clicked_app_download_banner';
export const SUBMITTED_PROM_EVENT = 'patient_submitted_prom';
export const CLICK_LOGIN_FORM_ID_SUBMIT_EVENT = 'patient_inserted_login_id_and_clicked_next';
export const CLICK_LOGIN_FORM_PASSWORD_SUBMIT_EVENT = 'patient_inserted_login_password_and_clicked_next';
export const CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD_EVENT = 'patient_clicked_login_password_showhide';
