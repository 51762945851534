import PropTypes from 'prop-types';

export const FETCH_STATUS = Object.freeze({
  UNSET: 'UNSET',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS', // Status that represents when a questionnaire is submitted
  ERROR: 'ERROR',
  START: 'START', // Status that represents when a questionnaire inside a submission or a submissions is being answered
  FINISH: 'FINISH' // Status that represents when a questionnaire is finished waiting to be submitted
});

export const fetchStatusPropType = PropTypes.oneOf(Object.values(FETCH_STATUS));

export default FETCH_STATUS;
