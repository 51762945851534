import { Button } from '@promptlylabs/skeleton';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';

const NoInternetWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorMessage = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: 30px;
  color: #474f54;
  margin-bottom: 40px;
  text-align: center;
`;

const ActionButton = styled(Button)`
  padding: 15px 30px !important;
`;

const NoInternetComponent = props => (
  <NoInternetWrapper>
    <Loader height={220} />
    <ErrorMessage>{props.t('No internet connection. Attempting to reconnect automatically...')}</ErrorMessage>
    <ActionButton color="primary" variant="contained" onClick={() => window.location.reload()}>
      {props.t('Refresh the page')}
    </ActionButton>
  </NoInternetWrapper>
);

NoInternetComponent.propTypes = {
  t: PropTypes.func.isRequired
};

export default withNamespaces()(NoInternetComponent);
