import { Button } from '@promptlylabs/skeleton';
import { white } from '@promptlylabs/ui-kit';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  background-color: ${white};
  padding: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Subtitle = styled.div`
  font-size: 16px;
  margin-bottom: 30px;
`;
const EventIdSpan = styled.span`
  margin-top: 10px;
  color: #88949d;
  font-size: 16px;
`;

const ErrorFallback = ({ t, eventId, onClick }) => (
  <Wrapper>
    <Title>Oops! {t('Something went wrong')}</Title>
    <Subtitle>
      {t('Please help us understand when this occured by reporting this error with the button bellow')}
    </Subtitle>
    <Button onClick={() => onClick(eventId)} variant="contained" color="primary">
      {t('Report this error')}
    </Button>
    <br />
    <EventIdSpan>{eventId}</EventIdSpan>
  </Wrapper>
);

ErrorFallback.propTypes = {
  t: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withNamespaces()(ErrorFallback);
