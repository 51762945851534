export const languages = [
  { title: 'EN', value: 'en', flag: 'us', label: 'English', phoneCountryCode: 'US' },
  { title: 'PT', value: 'pt', flag: 'pt', label: 'Português', phoneCountryCode: 'PT' },
  { title: 'ES', value: 'es', flag: 'es', label: 'Español', phoneCountryCode: 'ES' },
  { title: 'BR', value: 'pt-br', flag: 'br', label: 'Português Brasil', phoneCountryCode: 'BR' },
  { title: 'FR', value: 'fr-fr', flag: 'fr', label: 'Français', phoneCountryCode: 'FR' },
  { title: 'DA', value: 'da-dk', flag: 'dk', label: 'Dansk', phoneCountryCode: 'DA' },
  { title: 'Cz', value: 'cz', flag: 'cz', label: 'Czech', phoneCountryCode: 'CZ' },
  { title: 'EN-GB', value: 'en-gb', flag: 'gb', label: 'English (United Kingdom)', phoneCountryCode: 'GB' },
  { title: 'CROW', value: 'ach', flag: 'gb', label: 'CROWDIN' },
  { title: 'TH', value: 'th', flag: 'th', label: 'Thai', phoneCountryCode: 'TH' },
  // https://github.com/moment/moment/tree/develop/locale
  { title: 'CY', value: 'cy-gb', flag: 'gb-wls', label: 'Welsh', phoneCountryCode: 'GB' }
];
