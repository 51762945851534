import axios from 'axios';
import Sentry from '../../internals/sentry';
import { checkTargetApp } from './app';
/**
 * Config global for axios/django
 */

axios.defaults.headers.common['X-CSRFToken'] = axios.interceptors.request.use(config => {
  let csrfmiddlewaretoken;
  if (document && document.querySelector('[name=csrfmiddlewaretoken]')) {
    csrfmiddlewaretoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
  }
  config.headers.common['X-CSRFToken'] = csrfmiddlewaretoken;

  return config;
});

axios.defaults.withCredentials = true;

axios.defaults.headers.common['Accept-Language'] = axios.interceptors.request.use(config => {
  const lang = localStorage.getItem('lang') || navigator.language;
  config.headers.common['Accept-Language'] = lang.toLowerCase();
  return config;
});

axios.interceptors.request.use(config => {
  if (!config.headers['X-Promptly-Version']) {
    config.headers['X-Promptly-Version'] = checkTargetApp(window.FE_VERSION, window);
  }
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    Sentry.captureException(error);

    const { response } = error;

    if (response && response.status === 401) {
      window.location = '/login';
    }

    if (error.message === 'Network Error') {
      if (typeof window.setAppOffline === 'function') {
        window.setAppOffline();
      }
    }
    return Promise.reject(error);
  }
);

axios.setBaseURL = URL => {
  axios.defaults.baseURL = URL;
};

export default axios;

export const isStatusOk = response => response.status >= 200 && response.status < 300;

export const isStatus404 = response => response.status === 404;
export const isStatus400 = response => response.status === 400;
export const isStatus500 = response => response.status === 500;

export const hasPermissions = response => response.status === 404 || response.status === 403;
