import { get } from 'lodash';

export const getTranslationKey = (item, field, lang) => {
  const langNoDialect = lang.substr(0, 2);
  const langUnderScore = lang.replace('-', '_');

  const keysToCheck = [`${field}_${lang}`, `${field}_${langUnderScore}`, `${field}_${langNoDialect}`];

  return keysToCheck.find(key => Object.keys(item.i18n).includes(key));
};

export const getTrans = (item, field, lang) => {
  if (!item) {
    return '';
  }

  if (lang && item.i18n) {
    const translationKey = getTranslationKey(item, field, lang);

    // check if a valid translation key is found
    if (translationKey && item.i18n[translationKey]) {
      return item.i18n[translationKey];
    }
  }

  // fallback to the default field if no translation is found
  return item[field];
};

export const getCroUiTrans = (item, field, lang) => {
  let selectedTitle = item;
  if (item && item instanceof Object) {
    if (get(item, `${field}_${lang}`, null)) {
      selectedTitle = get(item, `${field}_${lang}`);
    } else {
      selectedTitle = get(item, `${field}_en`);
    }
  }

  return selectedTitle;
};

export const makeTrans = string => string;
