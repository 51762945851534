import * as Sentry from '@sentry/react';
import FE_VERSION from '../version';

Sentry.init({
  dsn: window.SENTRY_FE_DSN,
  environment: window.PROMPTLY_ENVIRONMENT,
  release: FE_VERSION,
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  // Capture Replay for 20% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0
});

window.Sentry = Sentry;

export default Sentry;
