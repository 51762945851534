import Error500 from 'components/500';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { makeTrans } from 'utils/translations';
import ErrorFallback from './ErrorFallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
    this.displayFallback = !!props.displayFallback;
    this.eventId = null;
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (process.env.NODE_ENV === 'production') {
      this.eventId = window.Sentry.captureException(error, { extra: errorInfo });
    }
  }
  onClick(eventId) {
    window.Sentry.showReportDialog({
      eventId,
      successMessage: makeTrans('Thank you for helping our team.'),
      title: makeTrans('Report this error'),
      subtitle: makeTrans('Help our team understand better how this error occurred.'),
      subtitle2: makeTrans('Thank you for helping us.'),
      labelSubmit: makeTrans('Submit report'),
      lang: localStorage.getItem('lang') || navigator.language
    });
  }
  render() {
    if (this.state.error) {
      return !this.displayFallback ? (
        <Error500 eventId={this.eventId} onClick={this.onClick} />
      ) : (
        <ErrorFallback t={this.t} eventId={this.eventId} onClick={this.onClick} />
      );
    }
    return this.props.children;
  }
}

export default withNamespaces()(ErrorBoundary);
