import { languages } from '../../internals/i18n/languages';
import { moment } from './dates';

const _getLang = (language, available) => available.find(l => l === language);

export const getNavigationLanguage = (language, available = []) => {
  if (language) {
    if (_getLang(language, available)) {
      return _getLang(language, available);
    }
    const [lang] = language.split('-');
    if (_getLang(lang, available)) {
      return _getLang(lang, available);
    }
  }

  return available.length > 0 ? available[0] : 'en';
};

export const getUserLanguage = () =>
  (
    localStorage.getItem('lang') ||
    navigator.languages?.[0] ||
    navigator.language ||
    navigator.userLanguage
  ).toLowerCase();

export const getSelectedLang = (lang, langOptions) => {
  const selectedLangExists = langOptions.map(l => l.value).includes(lang);
  if (selectedLangExists) {
    return lang;
  }
  return langOptions.length ? langOptions[0].value : undefined;
};

export const languageOptions = languages.filter(lang => window.ENABLED_LANGUAGES.includes(lang.value));

export const setLanguage = language => {
  moment.locale(language);
  localStorage.setItem('lang', language);
  window.i18next.changeLanguage(language);
};
