// USER
export const FETCH_BASIC_USER_DATA = 'FETCH_BASIC_USER_DATA';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const USER_UPDATE_DATA_BEGINS = 'USER_UPDATE_DATA_BEGINS';
export const USER_UPDATE_DATA = 'USER_UPDATE_DATA';
export const USER_UPDATE_DATA_ERROR = 'USER_UPDATE_DATA_ERROR';
export const USER_UPDATE_DATA_SIGNUP_DETAILS = 'USER_UPDATE_DATA_SIGNUP_DETAILS';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const FETCH_PROFESSIONAL_INSTITUTIONS = 'FETCH_PROFESSIONAL_INSTITUTIONS';
export const CHANGE_USER_INSTITUTION = 'CHANGE_USER_INSTITUTION';
export const FETCH_PATIENT_DATA = 'FETCH_PATIENT_DATA';
export const UPDATE_PATIENT_DISEASES = 'UPDATE_PATIENT_DISEASES';
export const FETCH_PROFESSIONAL_DATA = 'FETCH_PROFESSIONAL_DATA';
export const USER_PHONE_VALIDATED = 'USER_PHONE_VALIDATED';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const HEALTH_SPACE_PATIENT = 'HEALTH_SPACE_PATIENT';
export const SET_HEALTH_SPACE = 'SET_HEALTH_SPACE';
export const ENHANCE_HEALTH_SPACE_DATA = 'ENHANCE_HEALTH_SPACE_DATA';
export const FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES = 'FETCH_ALL_USER_DATA_FROM_HEALTH_SPACES';

// PREGNANCY
export const FETCH_PREGNANCY_MOVEMENT_REPORT = 'FETCH_PREGNANCY_MOVEMENT_REPORT';
export const FETCH_DEVELOPMENT_INFO_SUCCESS = 'FETCH_DEVELOPMENT_INFO_SUCCESS';
export const FETCH_PREGNANCY_BASE_DATE = 'FETCH_PREGNANCY_BASE_DATE';

// DISEASES
export const FETCH_DISEASES = 'FETCH_DISEASES';
export const FETCH_DISEASE_EVENTS = 'FETCH_DISEASE_EVENTS';
export const FETCH_SELECTED_EVENTS = 'FETCH_SELECTED_EVENTS';
export const ADD_DISEASE_EVENT = 'ADD_DISEASE_EVENT';
export const DELETE_DISEASE = 'DELETE_DISEASE';
export const DELETE_DISEASE_FAILER = 'DELETE_DISEASE_FAILER';
export const FETCH_DISEASE_BY_ID = 'FETCH_DISEASE_BY_ID';
export const UPDATED_DISEASE_INSTITUTION = 'UPDATED_DISEASE_INSTITUTION';
export const REMOVED_TEAM_MEMBER = 'REMOVED_TEAM_MEMBER';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const LOADING_ADD_TEAM_MEMBER = 'LOADING_ADD_TEAM_MEMBER';
export const PROM_SELECT_DISEASE = 'PROM_SELECT_DISEASE';
export const SET_SELECT_DISEASE = 'SET_SELECT_DISEASE';
export const UPDATE_TIMELINE = 'UPDATE_TIMELINE';
export const UPDATE_TIMELINE_LOADING = 'UPDATE_TIMELINE_LOADING';
export const ADD_NEW_SELECTED_EVENT = 'ADD_NEW_SELECTED_EVENT';
export const UPDATE_TREATMENT_EVENT = 'UPDATE_TREATMENT_EVENT';
export const FETCH_STATUS_BAR_ANSWER = 'FETCH_STATUS_BAR_ANSWER';
export const FETCH_STATUS_BAR_WARNINGS = 'FETCH_STATUS_BAR_WARNINGS';
export const CHANGE_TAG_WARNINGS_STATUS = 'CHANGE_TAG_WARNINGS_STATUS';
export const RESET_STATUS_BAR = 'RESET_STATUS_BAR';
export const DISEASE_TEAM_MEMBERS = 'DISEASE_TEAM_MEMBERS';
export const DISEASE_EVENTS_TIMELINE = 'DISEASE_EVENTS_TIMELINE';
export const DISEASE_EVENTS_TIMELINE_ERROR = 'DISEASE_EVENTS_TIMELINE_ERROR';
export const CHANGE_CARE_DOCTOR = 'CHANGE_CARE_DOCTOR';
export const FETCH_DISEASE_SCORES = 'FETCH_DISEASE_SCORES';
export const SET_SELECTED_DISEASE = 'SET_SELECTED_DISEASE';
export const FETCH_PATIENT_LAST_ASSESSMENT = 'FETCH_PATIENT_LAST_ASSESSMENT';
export const FETCH_PATIENT_REPORTED_SYMPTOMS = 'FETCH_PATIENT_REPORTED_SYMPTOMS';

// ACCOUNT
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS = 'UPDATE_ACCOUNT_SETTINGS_SUCCESS';
export const UPDATE_ACCOUNT_SETTINGS_ERROR = 'UPDATE_ACCOUNT_SETTINGS_ERROR';
export const UPDATE_ACCOUNT_SETTINGS_BEGINS = 'UPDATE_ACCOUNT_SETTINGS_BEGINS';
export const CLEAN_ACCOUNT_SETTINGS_ERRORS = 'CLEAN_ACCOUNT_SETTINGS_ERRORS';

// TOASTS
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// PATTIENT
export const SELECT_PATIENT_COA = 'SELECT_PATIENT_COA';
export const SELECT_PATIENT_INSTITUTION = 'SELECT_PATIENT_INSTITUTION';
export const FETCH_PATIENT_SCORES = 'FETCH_PATIENT_SCORES';
export const SET_PATIENT_INSTITUTIONS = 'SET_PATIENT_INSTITUTIONS';
export const PATIENT_CREATED = 'PATIENT_CREATED';
export const FETCH_PATIENT_TIMELINE_ASSESSMENTS = 'FETCH_PATIENT_TIMELINE_ASSESSMENTS';
export const FETCH_PATIENT_TODO_ASSESSMENTS = 'FETCH_PATIENT_TODO_ASSESSMENTS';
export const FETCH_PATIENT_HISTORY = 'FETCH_PATIENT_HISTORY';

// TODOLIST
export const FETCH_HOSPITAL_TODO_LIST = 'FETCH_HOSPITAL_TODO_LIST';

// PROFESSIONALS
export const FETCH_PROFESSIONALS_SUCCESS = 'FETCH_PROFESSIONALS_SUCCESS';
export const PROFESSIONAL_UPDATE_DATA = 'PROFESSIONAL_UPDATE_DATA';
export const FETCH_EDIT_PATIENT_SUCCESS = 'FETCH_EDIT_PATIENT_SUCCESS';
export const PATIENT_USER_UPDATE_DATA_ERROR = 'PATIENT_USER_UPDATE_DATA_ERROR';
export const PATIENT_USER_UPDATE_DATA = 'PATIENT_USER_UPDATE_DATA';
export const FETCH_READINGS = 'FETCH_READINGS';
export const FETCH_PATIENT_DISEASE_DATA = 'FETCH_PATIENT_DISEASE_DATA';
export const FETCH_HOSPITAL_PATIENTS_FILTERS = 'FETCH_HOSPITAL_PATIENTS_FILTERS';
export const FETCH_PROFESSIONAL_DISEASES = 'FETCH_PROFESSIONAL_DISEASES';

// DOCTORS
export const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_ERROR = 'FETCH_DOCTORS_ERROR';
export const FETCH_HOSPITAL_PATIENTS_LIST = 'FETCH_HOSPITAL_PATIENTS_LIST';
export const FETCH_HOSPITAL_HEADER_PATIENTS_LIST = 'FETCH_HOSPITAL_HEADER_PATIENTS_LIST';
export const FETCH_DOCTOR_PATIENTS_LIST = 'FETCH_DOCTOR_PATIENTS_LIST';
export const FETCH_DOCTOR_PATIENTS_PAGINATION = 'FETCH_DOCTOR_PATIENTS_PAGINATION';
export const FETCH_FOLLOW_UPS_LIST = 'FETCH_FOLLOW_UPS_LIST';
export const FETCH_DOCTOR_ASSESSMENTS_LIST = 'FETCH_DOCTOR_ASSESSMENTS_LIST';
export const FETCH_DOCTOR_ASSESSMENTS_PAGINATION = 'FETCH_DOCTOR_ASSESSMENTS_PAGINATION';
export const FETCH_DOCTOR_ASSESSMENTS_ERROR = 'FETCH_DOCTOR_ASSESSMENTS_ERROR';
export const ACTION_FOLLOW_UP_SUCCESS = 'ACTION_FOLLOW_UP_SUCCESS';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const RESET_SELECTED_PATIENT = 'RESET_SELECTED_PATIENT';
export const FEATCH_ACTION_BAR_ITEMS = 'FEATCH_ACTION_BAR_ITEMS';
export const PATIENT_FEATURED_STATUS = 'PATIENT_FEATURED_STATUS';
export const FETCH_WATCH_LIST = 'FETCH_WATCH_LIST';

// INSTITUTIONS
export const FETCH_INSTITUTIONS_SUCCESS = 'FETCH_INSTITUTIONS_SUCCESS';
export const FETCH_INSTITUTIONS_DISEASES = 'FETCH_INSTITUTIONS_DISEASES';

// HOSPITAL
export const FETCH_HOSPITAL_ASSESSMENTS_SUCCESS = 'FETCH_HOSPITAL_ASSESSMENTS_SUCCESS';
export const FETCH_HOSPITAL_ASSESSMENTS_PAGINATION = 'FETCH_HOSPITAL_ASSESSMENTS_PAGINATION';
export const FETCH_HOSPITAL_ASSESSMENTS_ERROR = 'FETCH_HOSPITAL_ASSESSMENTS_ERROR';
export const FETCH_HOSPITAL_FOLLOW_UPS_SUCCESS = 'FETCH_HOSPITAL_FOLLOW_UPS_SUCCESS';
export const FETCH_HOSPITAL_FOLLOW_UPS_PAGINATION = 'FETCH_HOSPITAL_FOLLOW_UPS_PAGINATION';
export const ADD_PATIENT_TO_HOSPITAL = 'ADD_PATIENT_TO_HOSPITAL';

// SETTINGS
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_LANGUAGE_OPTIONS_SUCCESS = 'FETCH_LANGUAGE_OPTIONS_SUCCESS';
export const FETCH_TIMEZONE_OPTIONS_SUCCESS = 'FETCH_TIMEZONE_OPTIONS_SUCCESS';
export const CHANGE_REGIONAL_PREF = 'CHANGE_REGIONAL_PREF';
export const FETCH_CLINICALTRIALS_SUCCESS = 'FETCH_CLINICALTRIALS_SUCCESS';
export const UPDATE_CLINICALTRIALS_SUCCESS = 'UPDATE_CLINICALTRIALS_SUCCESS';
export const ACCEPTED_CLINICAL_TRIAL = 'ACCEPTED_CLINICAL_TRIAL';
export const FETCH_CONTEXT_SCREEN_SUCCESS = 'FETCH_CONTEXT_SCREEN_SUCCESS';
export const ACCEPTED_CONTEXT_SCREEN = 'ACCEPTED_CONTEXT_SCREEN';
export const CHANGE_NAVIGATION_PREFERENCES_TODOLIST = 'CHANGE_NAVIGATION_PREFERENCES_TODOLIST';

// ENTITIES
export const FETCH_SPECIALITIES_SUCCESS = 'FETCH_SPECIALITIES_SUCCESS';
export const FETCH_ENTITY_DISEASES_SUCCESS = 'FETCH_ENTITY_DISEASES_SUCCESS';
export const FETCH_GEOLOCATIONS = 'FETCH_GEOLOCATIONS';
export const FETCH_CAREDOCTOR = 'FETCH_CAREDOCTOR';
export const FETCH_INSURANCES_SUCCESS = 'FETCH_INSURANCES_SUCCESS';

// PROMS
export const FETCH_PROM_SECTION = 'FETCH_PROM_SECTION';
export const FETCH_PROM_SUCCESS = 'FETCH_PROM_SUCCESS';
export const CHANGE_SELECTED_QUESTION = 'CHANGE_SELECTED_QUESTION';
export const CHANGE_QUESTION_VALUE = 'CHANGE_QUESTION_VALUE';
export const CHANGE_EXTRA_QUESTION_VALUE = 'CHANGE_EXTRA_QUESTION_VALUE';
export const CHANGE_QUESTION_TO_COMPLETED = 'CHANGE_QUESTION_TO_COMPLETED';
export const CREATE_PATIENT_DISEASE = 'CREATE_PATIENT_DISEASE';
export const CREATE_PATIENT_DISEASE_ERROR = 'CREATE_PATIENT_DISEASE_ERROR';
export const CREATE_PATIENT_DISEASE_SUCCESS = 'CREATE_PATIENT_DISEASE_SUCCESS';
export const SET_TRANSITION_TO_LOADING = 'SET_TRANSITION_TO_LOADING';
export const CLEAN_PROM_DATA = 'CLEAN_PROM_DATA';
export const CHANCE_CURRENT_PATH = 'CHANCE_CURRENT_PATH';
export const SET_RESUME_MODE = 'SET_RESUME_MODE';
export const FETCH_ASSESSMENT_SCORE = 'FETCH_ASSESSMENT_SCORE';
export const FETCH_PROM_PATIENT_DISEASE_INSTITUTION = 'FETCH_PROM_PATIENT_DISEASE_INSTITUTION';
export const PROM_PRESCRIBE_SUCCESS = 'PROM_PRESCRIBE_SUCCESS';

// ASSESSMENT REVIEWS
export const ASSESSMENTS_REVIEWS = 'ASSESSMENTS_REVIEWS';
export const CHANGE_SELECTED_REVIEW = 'CHANGE_SELECTED_REVIEW';

// CROS
export const CRO_CHANGE_EXTRA_QUESTION_VALUE = 'CRO_CHANGE_EXTRA_QUESTION_VALUE';
export const CRO_CHANGE_QUESTION_VALUE = 'CRO_CHANGE_QUESTION_VALUE';
export const CRO_SAVE_QUESTIONNAIRE = 'CRO_SAVE_QUESTIONNAIRE';
export const UPDATE_CRO_ASSESSMENT = 'UPDATE_CRO_ASSESSMENT';
export const CRO_REGISTRY_CHANGE_QUESTION_VALUE = 'CRO_REGISTRY_CHANGE_QUESTION_VALUE';
export const CRO_REGISTRY_CHANGE_EXTRA_QUESTION_VALUE = 'CRO_REGISTRY_CHANGE_EXTRA_QUESTION_VALUE';
export const RESET_SELECTED_REVIEW = 'RESET_SELECTED_REVIEW';
export const FETCH_CRO_ANSWER_FILE = 'FETCH_CRO_ANSWER_FILE';
export const UPLOAD_CRO_ANSWER_FILE = 'UPLOAD_CRO_ANSWER_FILE';
export const CRO_SET_GOTO = 'CRO_SET_GOTO';
export const APPLY_GO_TO = 'APPLY_GO_TO';
export const CRO_VALIDATION_ERROR = 'CRO_VALIDATION_ERROR';
export const CRO_CHANGE_QUESTION_OPTIONS = 'CRO_CHANGE_QUESTION_OPTIONS';
export const CRO_UPDATE_MANDATORIES = 'CRO_UPDATE_MANDATORIES';
export const SET_QUESTION_KEYS = 'SET_QUESTION_KEYS';

// TREATMENTS
export const FETCH_SELECTED_TREATMENTS = 'FETCH_SELECTED_TREATMENTS';
export const FETCH_TREATMENT_OPTIONS_SUCCESS = 'FETCH_TREATMENT_OPTIONS_SUCCESS';
export const ADD_NEW_TREATMENT = 'ADD_NEW_TREATMENT';

// EXAMS
export const FETCH_EXAMS_OPTIONS_SUCCESS = 'FETCH_EXAMS_OPTIONS_SUCCESS';
export const UPDATE_EXAM_SUCCESS = 'UPDATE_EXAM_SUCCESS';
export const ADD_NEW_SELECTED_EXAM = 'ADD_NEW_SELECTED_EXAM';
export const FETCH_EXAMS_LIST_SUCCESS = 'FETCH_EXAMS_LIST_SUCCESS';
export const RESET_SELECTED_EXAM = 'RESET_SELECTED_EXAM';
export const CHANGE_SELECTED_EXAM = 'CHANGE_SELECTED_EXAM';
export const EXAM_CHANGE_QUESTION_VALUE = 'EXAM_CHANGE_QUESTION_VALUE';

// EDUCATION
export const FETCH_EDUCATION_ARTICLES = 'FETCH_EDUCATION_ARTICLES';
export const FETCH_EDUCATION_BANNERS = 'FETCH_EDUCATION_BANNERS';
export const SEARCH_EDUCATION_ARTICLES = 'SEARCH_EDUCATION_ARTICLES';
export const FETCH_WARNING_SIGNS = 'FETCH_WARNING_SIGNS';
export const SELECT_EDUCATION_TAB = 'SELECT_EDUCATION_TAB';
export const FETCH_SECTIONS = 'FETCH_SECTIONS';
export const RESET_EDUCATION = 'RESET_EDUCATION';
export const RECOMMENDED_ARTICLES = 'RECOMMENDED_ARTICLES';

// EMBED
export const FETCHED_EMBED_SCHEMA = 'FETCHED_EMBED_SCHEMA';
export const FETCHED_EMBED_ITEMS = 'FETCHED_EMBED_ITEMS';
export const CHANGE_EMBED_ANSWER = 'CHANGE_EMBED_ANSWER';
export const TOGGLE_DELETE_EMBED_ITEM = 'TOGGLE_DELETE_EMBED_ITEM';
export const REMOVE_EMBED_ITEM = 'REMOVE_EMBED_ITEM';
export const ADD_EMBED_ITEM = 'ADD_EMBED_ITEM';

// Measurements
export const FETCH_MEASUREMENTS = 'FETCH_MEASUREMENTS';
export const MEASUREMENTS_JWT = 'MEASUREMENTS_JWT';
export const FETCH_MEASUREMENT_DETAIL = 'FETCH_MEASUREMENT_DETAIL';
export const FETCH_MEASUREMENT_DETAIL_READINGS = 'FETCH_MEASUREMENT_DETAIL_READINGS';
export const RESET_MEASUREMENT_DETAIL_READINGS = 'RESET_MEASUREMENT_DETAIL_READINGS';
export const SAVE_MEASUREMENT = 'SAVE_MEASUREMENT';
export const FETCH_SCALES_MEASUREMENT = 'FETCH_SCALES_MEASUREMENT';
export const FETCH_TIMELINE = 'FETCH_TIMELINE';
export const FETCH_TIMELINE_NEXT_PAGE = 'FETCH_TIMELINE_NEXT_PAGE';
export const MEASUREMENT_CODE_CHANGE = 'MEASUREMENT_CODE_CHANGE';
export const FETCH_READINGS_RANGES = 'FETCH_READINGS_RANGES';

// Administrive
export const FETCH_ADMINISTRATIVE_PATIENTS_LIST = 'FETCH_ADMINISTRATIVE_PATIENTS_LIST';
export const FETCH_ADMINISTRATIVE_PATIENTS_PAGINATION = 'FETCH_ADMINISTRATIVE_PATIENTS_PAGINATION';
export const FETCH_ADMINISTRATIVE_PATIENTS_FILTERS = 'FETCH_ADMINISTRATIVE_PATIENTS_FILTERS';
export const FETCH_ADMINISTRATIVE_ACTION_BAR_ITEMS = 'FETCH_ADMINISTRATIVE_ACTION_BAR_ITEMS';
export const CHANGE_ADMINISTRATIVE_PATIENT_FILTERS = 'CHANGE_ADMINISTRATIVE_PATIENT_FILTERS';
export const RESET_ADMINISTRATIVE_PATIENT_FILTERS = 'RESET_ADMINISTRATIVE_PATIENT_FILTERS';
export const CHANGE_ADMINISTRATIVE_PATIENT_PAGE = 'CHANGE_ADMINISTRATIVE_PATIENT_PAGE';
export const CHANGE_ADMINISTRATIVE_SEARCH_QUERY = 'CHANGE_ADMINISTRATIVE_SEARCH_QUERY';
export const CHANGE_ADMINISTRATIVE_ORDERING = 'CHANGE_ADMINISTRATIVE_ORDERING';

// EXPORT
export const EXPORT_CLINICAL_DATA = 'EXPORT_CLINICAL_DATA';

// Engagement Analytics
export const FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_SUCCESS = 'FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_SUCCESS';
export const FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_ERROR = 'FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_ERROR';
export const FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_STARTED = 'FETCH_LOGGED_USER_ENGAGEMENT_ANALYTICS_STARTED';

// Data collection
export const SET_PASSWORD = 'SET_PASSWORD';
export const CLICK_APP_DOWNLOAD_BANNER = 'CLICK_APP_DOWNLOAD_BANNER';
export const ACCEPT_AGREEMENT = 'ACCEPT_AGREEMENT';
export const SUBMITTED_PROM = 'SUBMITTED_PROM';
export const CLICK_LOGIN_FORM_ID_SUBMIT = 'CLICK_LOGIN_FORM_ID_SUBMIT';
export const CLICK_LOGIN_FORM_PASSWORD_SUBMIT = 'CLICK_LOGIN_FORM_PASSWORD_SUBMIT';
export const CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD = 'CLICK_LOGIN_FORM_SHOWHIDE_PASSWORD';
export const CLICK_ADD_PATIENT = 'dataCollection.AddPatient/CLICK_ADD_PATIENT';
export const CLICK_VALIDATE_PATIENT_CONTACT = 'dataCollection.AddPatient/CLICK_VALIDATE_PATIENT_CONTACT';
export const CLICK_START_PATIENT_QUESTIONNAIRE = 'dataCollection.AddPatient/CLICK_START_PATIENT_QUESTIONNAIRE';
export const CLICK_START_CLINICIAN_QUESTIONNAIRE = 'dataCollection.AddPatient/CLICK_START_CLINICIAN_QUESTIONNAIRE';
export const CLICK_CANCEL_ADD_PATIENT = 'dataCollection.AddPatient/CLICK_CANCEL_ADD_PATIENT';
export const CLICK_ADD_CANDIDATE_PATIENT = 'dataCollection.AddPatient/CLICK_ADD_CANDIDATE_PATIENT';
export const CLICK_OPEN_PATIENT_EXPAND = 'professional.expand.patient_info.in_patients_list';
export const CLICK_OPEN_PATIENT_DETAIL_EXPAND = 'professional.click.see_details.in_patient_expanded_info';
export const CLICK_OPEN_PATIENT_KEBAB = 'professional.click.patient_kebab.in_patients_list';
export const CLICK_OPEN_PATIENT_CONDITION_KEBAB = 'professional.click.patient_condition.in_patient_kebab';
export const CLICK_START_PATIENT_ASSESSMENT_KEBAB = 'professional.start.patient_assessment.in_patient_kebab';
export const CLICK_REQUEST_PATIENT_ASSESSMENT_KEBAB = 'professional.request.patient_assessment.in_patient_kebab';
export const CLICK_SEND_QUESTIONNAIRE_TO_PATIENT = 'professional.click.sendQuestionnaireToPatient';
export const CLICK_OPEN_PATIENT_CONDITION_ROW = 'professional.click.patient_condition.in_patients_list_row';
export const CLICK_OPEN_PATIENTS_SEARCH_FILTERS = 'professional.click.filters.in_patients_list_search';
export const TRACK_PROFESSIONAL_GLOBAL_SEARCH = 'professional.search.in_global_search';
export const TRACK_PROFESSIONAL_PATIENT_SEARCH = 'professional.search.in_patient_list_search';
export const TRACK_PATIENTS_LIST_CHANGE_PAGE = 'professional.change.page.in_patient_list_search';
export const ENTER_PROM_RESUME = 'patient.enter.prom_resume';
export const CLICK_PROM_FINISH = 'patient.click.finish.in_prom_resume';
export const CLICK_OPEN_ASSESSMENTS_NAV = 'professional.open.assessments_nav.in_patients_overview';
export const CLICK_EXPAND_EVENT_TIMELINE = 'professional.expand.events_timeline.in_patients_overview';
export const START_PROM_SPECIFIC_TIMING_OVERVIEW = 'professional.start.prom_specific_timing.in_patients_overview';
export const START_PROM_SPECIFIC_TIMING_ASSESSMENTS = 'professional.start.prom_specific_timing.in_patients_assessments';
export const CLICK_OPEN_EDIT_EVENT_DATE = 'professional.open.edit_event_date_form.in_assessments';
export const EDIT_EVENT_DATE = 'professional.edit.event_date.in_assessments';
export const CANCEL_EDIT_EVENT_DATE = 'professional.cancel.edit_event_date.in_assessments';
export const EVENT_CREATED = 'professional.create.event.in_patient_overview';
export const CRO_TAB_CHANGED = 'professional.change.cro_tab.in_assessments';
export const CRO_SAVED = 'professional.save.cro.in_assessments';
export const CLICK_COPY_TO_CLIPBOARD = 'professional.clicks.copy_to_clipboard.in_assessments';
export const TRACK_OPEN_SCORE_APPRECIATION = 'user.open.score_thank_you_card.in_prom_resume';
export const TRACK_OPEN_ASSESSMENTS_INFO = 'patient.open.assessments_info_card.in_prom_resume';
export const CLICK_ANSWER_NEXT_PROM = 'patient.clicks.answer_now.in_prom_resume';
export const CLICK_NEXT_QUESTIONAIRE_TIMELINE_EXPAND = 'patient.click.next_questionaire_timeline.expand';
export const CLICK_AGREEMENT_SCROLL_TO_BOTTOM = 'patient.click.agreement.scroll_to_bottom';
export const CLICK_ADD_NEW_DISEASE_KEBAB_ADMINISTRATIVE = 'administrative.click.add_new_condition.kebab';
export const CLICK_ADD_NEW_DISEASE_NEW_CONDITION_ADMINISTRATIVE =
  'administrative.click.add_new_condition.new_condition_page';
export const CLICK_ADD_NEW_DISEASE_NEW_CONDITION = 'professional.click.add_new_condition.new_condition_page';
export const OPEN_PATIENT_CONDITION_MENU_OVERVIEW = 'professional.open.condition_menu.patient_disease_detail';
export const SELECT_PATIENT_CONDITION = 'professional.select.patient_condition.patient_disease_detail';
export const CLICK_NEW_CONDITION_OVERVIEW = 'professional.click.add_new_condition.patient_disease_detail';
export const START_NEW_PROM_NEW_CONDITION = 'clinician.start.new_prom.new_condition_page';
export const START_NEW_CROM_NEW_CONDITION = 'clinician.start.new_crom.new_condition_page';
export const CLICK_ADD_NEW_DISEASE_KEBAB = 'clinician.click.add_new_condition.kebab';
export const CLICK_ADD_NEW_DISEASE_EXPAND = 'clinician.click.add_new_condition.expand';
export const CLICK_DISEASE_TAB_EXPAND = 'clinician.click.disease_tab.expand';
export const CLICK_PATIENT_MEASUREMENTS_TAB = 'professional.clicks.patient_measurements_tab';
export const CLICK_PATIENT_MEASUREMENTS_WIDGET = 'professional.clicks.patient_measurements_widget';
export const CLICK_FEATURED_PATIENT_TOGGLE = 'professional.clicks.featured_patient_toggle';
export const CLICK_PATIENT_DETAIL_FEATURED_PATIENT = 'professional.clicks.patient_detail_featured_patient';
export const CLICK_REFER_PATIENT_KEBAB = 'professional.clicks.refer_patient.kebab';
export const CLICK_REFER_PATIENT_SUBMIT = 'professional.clicks.refer_patient.submit';
export const CLICK_REFER_PATIENT_SELECT_DOCTOR = 'professional.clicks.refer_patient.select_doctor';
export const CLICK_ACCEPT_CONTEXT_SCREEN = 'patient.clicks.context_screen.accept';
export const ENTER_CONTEXT_SCREEN = 'patient_enter_contextScreen';
export const CLICK_MAIN_NAV = 'professional.clicks.mainNav';
export const CLICK_QUICK_FILTER_ASSESSMENTS_LIST = 'professional.clicks.quickFilter.assessmentsList';
export const CLICK_OPEN_FILTER_ASSESSMENTS_LIST = 'professional.clicks.openFilter.assessmentsList';
export const ASSESSMENTS_LIST_FILTER_ON = 'professional.filtered.on.assessmentsList';
export const ASSESSMENTS_LIST_CLEAR_FILTER = 'professional.clicks.clearFilter.assessmentsList';
export const ASSESSMENTS_LIST_SEARCHED_ON = 'professional.searched.on.assessmentsList';
export const IDENTIFY_USER_AS_PATIENT = 'user.identify.as.patient';
export const CLICK_OPT_OUT = 'user.clicks.opt_out';
export const CLICK_CONFIRM_OPT_OUT = 'user.clicks.confirm.opt_out';
export const CLICK_CHANGE_LANGUAGE = 'user.clicks.change.language';
export const ENTER_IDENTITY_CHECKER_PAGE = 'user_openView_idChecker_onidCheckerFlow';
export const CLICK_PROCCED_BTN_IDENTITY_CHECKER = 'user_click_idCheckerBtn_onidCheckerFlow';
export const ENTER_IDENTITY_CHECKER_REVALIDATION_PAGE = 'user_openView_idCheckerValitationFailed_onidCheckerFlow';
export const CLICK_PROCCED_BTN_IDENTITY_CHECKER_REVALIDATION = 'user_click_idCheckerRevalidationBtn_onidCheckerFlow';
export const CLICK_OPT_OUT_BTN_IDENTITY_CHECKER = 'user_click_cancelComunication_onidCheckerFlow';
export const ENTER_IDENTITY_CHECKER_CANCEL_COMMS_PAGE = 'user_openView_comunicationCanceled_onidCheckerFlow';
export const CLICK_RESUME_QUESTIONNAIRE_BUTTON = 'patient_clicked_resumeQuestionnaire_onQuestionnaireUI';
export const CLICK_RESTART_QUESTIONNAIRE_BUTTON = 'patient_clicked_restartedQuestionnaire_onQuestionnaireUI';
export const DISPLAY_VALIDATE_ACCOUNT_ON_QUESTIONNAIRE = 'user_valitateAccount_on_questionnaire_was_displayed';
export const CLICK_LEARN_MORE_QUESTIONNAIRE_RESULTS = 'patient_click_learnMore_on_questionnaireResults';
export const TRACK_OPEN_TC_PAGE = 'patient_open_t&c_page';
export const TRACK_CLICK_REJECT_TC = 'patient_clicked_reject_t&c';
export const TRACK_CLICK_GOBACK_REJECT_TC = 'patient_clicked_goback_on_t&c_reject_confirmation_modal';
export const TRACK_OPEN_INSTITUTION_CONSENT_PAGE = 'patient_open_institutionConsent_page';
export const TRACK_OPEN_CONNECT_DEVICE_PAGE = 'professional_opened_connectDeviceScreen';
export const TRACK_CLICK_CONTINUE_CONNECT_DEVICE_PAGE = 'professional_clicked_continue_on_connectDeviceScreen';
export const TRACK_CLICK_BACK_CONFIRM_DEVICE_PAGE = 'professional_clicked_back_on_connectDeviceScreen';
export const TRACK_CLICK_CONNECT_CONFIRM_DEVICE_PAGE = 'professional_clicked_connectDevice_on_connectDeviceScreen';
export const TRACK_SUCCESS_CONNECT_DEVICE = 'professional_successfully_connectedDevice';
export const TRACK_OPEN_DEVICE_WELCOME_PAGE = 'patient_opened_welcomeScreen';
export const TRACK_CLICK_START_DEVICE_WELCOME_PAGE = 'patient_clicked_startBtn_on_welcomeScreen';
export const TRACK_OPEN_DEVICE_NO_QUESTIONNAIRES = 'patient_opened_noQuestionnaireAvailableScreen';
export const TRACK_OPEN_DEVICE_LANGUAGE_MENU = 'user_opened_changeLanguage';
export const TRACK_CLICK_DEVICE_LANGUAGE_MENU = 'user_changed_language';
export const TRACK_CLICK_SUBMIT_SUBMISSION = 'user_clicked_finalSubmission';

// MyQuest data collection
export const ENTER_MY_QUEST = 'myQuest_patient_openView_homePage';
export const CLICK_AVAILABLE_QUESTIONNAIRES = 'myQuest_patient_click_availableQuestionnaires_onHomePage';
export const CLICK_CONTINUE_QUESTIONNAIRES = 'myQuest_patient_click_continueQuestionnaires_onHomePage';
export const CLICK_QUESTIONNAIRES_RESULTS = 'myQuest_patient_click_questionnaireResults_onHomePage';
export const START_QUESTIONNAIRE_ON_AVAILABLE_QUESTIONNAIRES =
  'myQuest_patient_start_questionnaire_onAvailableQuestionnaires';
export const CONTINUE_QUESTIONNAIRE_ON_ONGOING_QUESTIONNAIRES =
  'myQuest_patient_start_questionnaire_onOngoingQuestionnaires';
export const OPEN_PROFILE_MYQUEST = 'myQuest_patient_open_profileMenu';
export const OPEN_SETTINGS_MYQUEST = 'myQuest_patient_open_settings';
export const MYQUEST_LOGOUT = 'myQuest_patient_click_logout';

// Todo list V2

export const PROFESSIONAL_OPENS_TODOLIST = 'professional_opened_toDoList';
export const PROFESSIONAL_CHANGES_TAB_TODOLIST = 'professional_click_tabView_on_toDoList';
export const PROFESSIONAL_SEARCHES_TODOLIST = 'professional_searched_on_toDoList';
export const PROFESSIONAL_CLICKS_KEBAB_MANAGE_TODOLIST = 'professional_click_kebab_on_toDoList';
export const PROFESSIONAL_CLICKS_KEBAB_HEADER_TODOLIST = 'professional_click_kebabColumnHeader_on_toDoList';
export const PROFESSIONAL_HOVERS_PATIENTINFO_TODOLIST = 'professional_hovers_patientInfo_on_toDoList';
export const PROFESSIONAL_HOVERS_COMMSHISTORY_TODOLIST = 'professional_hovers_commsHistory_on_toDoList';
export const PROFESSIONAL_UPDATE_SORT_TODOLIST = 'professional_updates_sorting_onToDoList';
export const PROFESSIONAL_UPDATE_FILTER_TODOLIST = 'professional_updates_filters_on_ToDoList';
export const PROFESSIONAL_CLICKS_KEBAB_TABLELINE_TODOLIST = 'professional_click_kebabTableLine_on_toDoList';
export const PROFESSIONAL_CLICKS_TRANSCRIBEPROM_TODOLIST = 'professional_click_transcribeProm_on_toDoList';
export const PROFESSIONAL_CLICKS_SENDPROM_TODOLIST = 'professional_click_sendPromToPatient_on_toDoList';
export const PROFESSIONAL_CLICKS_MANAGE_COLUMNS_TODOLIST =
  'professional_click_manageColumns_on_toDoListKebabColumnHeader';
export const PROFESSIONAL_CLICKS_CLOSE_MANAGE_COLUMNS_TODOLIST =
  'professional_click_closeManageVisibleColumns_on_toDoList';
export const PROFESSIONAL_CLICKS_SAVE_MANAGE_COLUMNS_TODOLIST =
  'professional_click_saveManageVisibleColumns_on_toDoList';

// Theme
export const SET_THEME = 'SET_THEME';

// ACTIONS BAR
export const ADD_EVENT_RELOAD_REQUIRED = 'ADD_EVENT_RELOAD_REQUIRED';
export const ADD_EVENT_RELOAD_COMPLETED = 'ADD_EVENT_RELOAD_COMPLETED';

// RAG

export const DATA_TABLE_WIDGET_CLICK_MANAGE_COLUMNS_ON_KEBAB_COLUMN_HEADER =
  'daTaTableWidget_click_manageColumns_on_KebabColumnHeader';

export const DATA_TABLE_WIDGET_USER_UPDATE_FILTERS = 'daTaTableWidget_user_update_filters';

export const DATA_TABLE_WIDGET_USER_UPDATE_SORTING = 'daTaTableWidget_user_update_sorting';

export const DATA_TABLE_WIDGET_USER_CLICK_CLOSE_MANAGE_VISIBLE_COLUMNS =
  'daTaTableWidget_user_click_closeManageVisibleColumns';

export const DATA_TABLE_WIDGET_USER_CLICK_LINE_ACTION_BUTTON = 'daTaTableWidget_user_click_lineActionButton';

export const DATA_TABLE_WIDGET_USER_CLICK_KEBAB_COLUMN_HEADER = 'daTaTableWidget_user_click_kebabColumnHeader';

export const DATA_TABLE_WIDGET_USER_CLICK_TABLE_LINE = 'daTaTableWidget_user_click_tableLine';

export const DATA_TABLE_WIDGET_USER_CLICK_SAVE_MANAGE_VISIBLE_COLUMNS =
  'daTaTableWidget_user_click_saveManageVisibleColumns';

export const DATA_TABLE_WIDGET_USER_OPEN_PATIENT_INFO = 'daTaTableWidget_user_open_patientInfo';

export const DATA_TABLE_WIDGET_USER_PERFORMED_A_SEARCH = 'daTaTableWidget_user_performedASearched';

export const DATA_TABLE_WIDGET_USER_CLICK_MANAGE_COLUMNS_ON_DATA_TABLE_KEBAB_COLUMN_HEADER =
  'daTaTableWidget_user_click_manageColumns_on_dataTableKebabColumnHeader';
