export const getStaticUrl = path => `${window.STATIC_URL}build/${path}`;

export const getLogoUrl = path => {
  const file = path.split('\\').pop().split('/').pop();
  const pathOnly = path.replace(file, '');

  return getStaticUrl(`${pathOnly}new_logo/${file}`);
};

export const getPromptlyLogoURL = isSkeletonV2 => {
  const logo = isSkeletonV2 ? 'assets/images/logo.svg' : 'assets/images/prom-logo-white.svg';
  return getLogoUrl(logo);
};
