import i18next from 'i18next';
import { getNavigationLanguage, getUserLanguage } from 'utils/language';
import translationsCROWDIN from '../../src/locales/ach/app.json';
import translationsCS from '../../src/locales/cs/app.json';
import translationsCY from '../../src/locales/cy/app.json';
import translationsDA from '../../src/locales/da/app.json';
import translationsEN from '../../src/locales/en/app.json';
import translationsES from '../../src/locales/es/app.json';
import translationsFR from '../../src/locales/fr/app.json';
import translationsPT from '../../src/locales/pt/app.json';
import translationsPTBR from '../../src/locales/pt_BR/app.json';
import translationsTH from '../../src/locales/th/app.json';
import { API_KEY, PROJECT_ID } from './config';

class I18nSettings {
  constructor(lang = 'pt-br', defaultNS = 'app', fallbackLng = 'en') {
    const backendOptions = {
      projectId: PROJECT_ID,
      apiKey: API_KEY,
      referenceLng: 'en',
      nsSeparator: '::',
      keySeparator: '=>'
    };

    return i18next.init({
      backend: backendOptions,
      interpolation: { escapeValue: false },
      lng: lang,
      defaultNS,
      fallbackLng,
      returnEmptyString: false,
      nsSeparator: '::',
      keySeparator: '=>',
      resources: {
        en: {
          app: translationsEN
        },
        da: {
          app: translationsDA
        },
        pt: {
          app: translationsPT
        },
        fr: {
          app: translationsFR
        },
        es: {
          app: translationsES
        },
        'pt-BR': {
          app: translationsPTBR
        },
        cs: {
          app: translationsCS
        },
        th: {
          app: translationsTH
        },
        'cy-GB': {
          app: translationsCY
        },
        ach: {
          app: translationsCROWDIN
        }
      }
    });
  }
}

const available = window.ENABLED_LANGUAGES.split(',');
const userLanguage = getUserLanguage();
const selectedLang = userLanguage ? getNavigationLanguage(userLanguage, available) : available[0];

localStorage.setItem('lang', selectedLang);

const _i18next = new I18nSettings(selectedLang);
window.i18next = _i18next;
export default _i18next;
