import { makeTrans } from 'utils/translations';

export const GLUCOSE_ID = '15074-8';
export const HEART_RATE_ID = '8867-4';
export const WEIGHT_ID = '3141-9';
export const SYSTOLIC_BLOOD_PRESSURE_CODE = '55284-4';
export const DIASTOLIC_BLOOD_PRESSURE_CODE = '8462-4';
export const BLOOD_PRESSURE_CODE = '8480-6';
export const ECG_CODE = 'ecg';
export const STEPS = 'steps';
export const RESTING_HEART_RATE_CODE = 'resting-heart-rate';
export const LATEST_HEART_RATE_CODE = '8867-4';
export const EXERCISE_INTENSITY_CODE = 'exercise-intensity';
export const GLUCOSE_POS_MEAL_CODE = 'glucose-pos-meal';
export const GLUCOSE_PRE_MEAL_CODE = 'glucose-pre-meal';
export const GLUCOSE_FASTING_CODE = 'glucose-fasting';
export const ECG_AFIB_CODE = 'ecg-afib';

export const ECG_CATEGORY = 'ecg';
export const ECG_AFIB = 'ecg-afib';
export const ECG_CHART = 'ecg-chart';

export const GLUCOSE_URL = 'glucose';
export const WEIGHT_URL = 'weight';
export const BLOOD_PRESSURE_URL = 'blood-pressure';

export const codeToUrl = {
  [GLUCOSE_ID]: [GLUCOSE_URL],
  [WEIGHT_ID]: [WEIGHT_URL],
  [SYSTOLIC_BLOOD_PRESSURE_CODE]: [BLOOD_PRESSURE_URL],
  [DIASTOLIC_BLOOD_PRESSURE_CODE]: [BLOOD_PRESSURE_URL],
  [BLOOD_PRESSURE_CODE]: [BLOOD_PRESSURE_URL],
  [HEART_RATE_ID]: [BLOOD_PRESSURE_URL],
  [RESTING_HEART_RATE_CODE]: [BLOOD_PRESSURE_URL],
  [STEPS]: [STEPS],
  [EXERCISE_INTENSITY_CODE]: [STEPS],
  [ECG_CODE]: [BLOOD_PRESSURE_URL],
  [GLUCOSE_FASTING_CODE]: [GLUCOSE_URL],
  [GLUCOSE_POS_MEAL_CODE]: [GLUCOSE_URL],
  [GLUCOSE_PRE_MEAL_CODE]: [GLUCOSE_URL],
  [ECG_AFIB]: [BLOOD_PRESSURE_URL]
};

export const READINGS_DAY_SECTIONS = {
  overnight: { begin: '00:00', end: '06:59' },
  breakfast: { begin: '07:00', end: '08:59' },
  morning: { begin: '09:00', end: '11:59' },
  lunch: { begin: '12:00', end: '13:59' },
  afternoon: { begin: '14:00', end: '18:59' },
  dinner: { begin: '19:00', end: '20:59' },
  bedtime: { begin: '21:00', end: '23:59' }
};

export const READINGS_RANGES_FILTER = 'glucose-fasting,glucose-pre-meal,glucose-pos-meal';

export const READINGS_TABS = [
  {
    value: 'table',
    label: makeTrans('Table')
  },
  {
    value: 'chart',
    label: makeTrans('Chart')
  }
];

export const GLUCOSE_TABLE_COLUMNS = [
  {
    key: 'day',
    name: makeTrans('Day'),
    avgLabel: makeTrans('Average')
  },
  {
    key: 'overnight',
    name: makeTrans('Overnight'),
    time: makeTrans('12am to 7am')
  },
  {
    key: 'breakfast',
    name: makeTrans('Breakfast'),
    time: makeTrans('7am to 9am')
  },
  {
    key: 'morning',
    name: makeTrans('Morning'),
    time: makeTrans('9am to 12pm')
  },
  {
    key: 'lunch',
    name: makeTrans('Lunch'),
    time: makeTrans('12pm to 2pm')
  },
  {
    key: 'afternoon',
    name: makeTrans('Afternoon'),
    time: makeTrans('2pm to 7pm')
  },
  {
    key: 'dinner',
    name: makeTrans('Dinner'),
    time: makeTrans('7pm to 9pm')
  },
  {
    key: 'bedtime',
    name: makeTrans('Bedtime'),
    time: makeTrans('9pm to 12am')
  }
];
