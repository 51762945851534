import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import loadingAnimation from './animation.json';
import './style.scss';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => (props.height ? `${props.height}px` : 'calc(100vh - 200px)')};
  opacity: 0;
  animation: fade 0.4s 0.3s forwards;
  animation-timing-function: ease-in-out;
  zoom: 0.75;
  padding: 20px;
`;

const Loader = props => (
  <LoaderWrapper height={props.height} data-testid="Loader">
    <Lottie animationData={loadingAnimation} style={{ height: '300px' }} />
  </LoaderWrapper>
);

Loader.defaultProps = {
  height: undefined
};

Loader.propTypes = {
  height: PropTypes.number
};

export default Loader;
