import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { Offline } from 'react-detect-offline';
import NoInternetComponent from '../NoInternetComponent';
import ServerDownComponent from '../ServerDownComponent';

class NetworkAwareContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userIsOnline: true,
      hideOfflinePreview: false
    };

    this.setAppOffline = () => {
      this.setState({ userIsOnline: false });
    };

    window.setAppOffline = this.setAppOffline;
  }

  componentWillUnmount() {
    window.setAppOffline = undefined;
  }

  handleOnlineStateChange = userIsOnline => {
    if (userIsOnline) {
      this.setState({ userIsOnline: true, hideOfflinePreview: false });
    } else {
      this.setState({ hideOfflinePreview: true });
    }
  };

  render() {
    return (
      <Fragment>
        {!this.state.userIsOnline && window.PROMPTLY_ENVIRONMENT !== 'development' && (
          <Fragment>
            {!this.state.hideOfflinePreview && <ServerDownComponent />}
            <Offline onChange={this.handleOnlineStateChange} polling={{ interval: 1500 }}>
              <NoInternetComponent />
            </Offline>
          </Fragment>
        )}
        {(this.state.userIsOnline || window.PROMPTLY_ENVIRONMENT === 'development') && this.props.children}
      </Fragment>
    );
  }
}

NetworkAwareContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default NetworkAwareContainer;
